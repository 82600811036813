var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
        : _c("grid", {
            attrs: { "data-items": _vm.items, columns: _vm.columns },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }