<template>
     <div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="py-2 ml-n3"
            >
              <v-btn-toggle
                v-model="toggle_exclusive"
                group
              >
                <v-btn v-show="this.filter != 'all'" :ripple="high" :elevation="high ? 1 : 0" @click="highAlert" width="160">
                  <span class="hidden-sm-and-down">High Alert</span>
                  <v-avatar
                    color="bg-gradient-primary border-radius-xl ml-2 mt-0"
                    class="shadow-primary"
                    height="24"
                    width="24"
                    min-width="12"
                  >
                    <v-icon class="material-icons-round text-white" size="16"
                      >priority_high</v-icon
                    >
                  </v-avatar>
                </v-btn>
                <v-btn v-show="this.filter != 'all'" :ripple="medium" :elevation="medium ? 1 : 0" @click="mediumAlert" width="160">
                  <span class="hidden-sm-and-down">Medium Alert</span>
                  <v-avatar
                    color="bg-gradient-warning border-radius-xl ml-2 mt-0"
                    class="shadow-warning"
                    height="24"
                    width="24"
                    min-width="12"
                  >
                    <v-icon class="material-icons-round text-white" size="16"
                      >warning</v-icon
                    >
                  </v-avatar>
                </v-btn> 
                <v-btn v-show="this.filter != 'all'" :ripple="all" :elevation="all ? 1 : 0" @click="allSites"  width="160">
                  <span class="hidden-sm-and-down">All Sites</span>
                  <v-avatar
                    color="bg-gradient-success border-radius-xl ml-2 mt-0"
                    class="shadow-success"
                    height="24"
                    width="24"
                    min-width="12"
                  >
                    <v-icon class="material-icons-round text-white" size="16"
                      >dataset</v-icon
                    >
                  </v-avatar>
                </v-btn>
                <v-btn v-show="this.filter != 'all'" elevation="1">
                  <span>Count: </span>
                  <v-avatar
                    v-if="this.high"
                    class="ml-4"
                    color="bg-gradient-primary"
                    size="30"
                  >
                    <span class="font-weight-bold text-white text-sm">{{ c }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else-if="this.medium"    
                    class="ml-4"
                    color="bg-gradient-warning"
                    size="30"
                  >
                    <span class="font-weight-bold text-white text-sm">{{ c }}</span>
                  </v-avatar>
                  <v-avatar
                    v-else
                    class="ml-4"
                    color="bg-gradient-success"
                    size="30"
                  >
                    <span class="font-weight-bold text-white text-sm">{{ c }}</span>
                  </v-avatar>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row> 
        </v-container>  
        <v-card class="card-shadow border-radius-xl">
          <v-card-text class="px-0 py-0">
            <v-data-table
              ref="datatable"
              :headers="headers"
              :items="sites"
              :single-expand="singleExpand"
              :expanded.sync="expandedIds"
              item-key="id"
              show-expand
              :search="search"
              :custom-filter="customSearch"
              @current-items="getFiltered"
              class="table"
              :page.sync="page"
              hide-default-footer
              @page-count="pageCount = $event"
              :items-per-page="itemsPerPage"
              mobile-breakpoint="0"
            >
              <template v-slot:top>
                <v-toolbar flat height="80">
                  <v-row>
                    <v-col cols="12" md="5">
                      <v-text-field
                        hide-details
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          input-icon
                        "
                        dense
                        flat
                        filled
                        solo
                        height="43"
                        v-model="search"
                        placeholder="Search"
                      >
                        <template slot="prepend-inner">
                          <v-icon
                            color="#adb5bd"
                            size="16"
                            class="material-icons-round mt-n2"
                            >search</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-btn @click="expandAll()">Expand All</v-btn>
                    </v-col>
                  </v-row>

                  <v-spacer></v-spacer>
                  <v-dialog v-model="reportDialog" persistent max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :ripple="false"
                        :elevation="0"
                        height="43"
                        class="
                          font-weight-bold
                          text-xs
                          btn-primary
                          bg-gradient-primary
                        "
                        id="portal_excel"
                        @click="expandPage"
                      >
                        Export Report
                      </v-btn>
                    </template>
                    <v-card class="card-shadow border-radius-xl">
                        <div class="card-header-padding card-border-bottom">
                          <span class="font-weight-bold text-h5 text-typo mb-0">
                            Export Report
                          </span>
                        </div>
                        <div class="ml-12">
                          <v-date-picker
                            v-model="reportDatesRange"
                            range
                            color="pink"
                            width="500px"
                            show-adjacent-months
                          >
                          </v-date-picker>
                        </div>
                      <v-card-text class="mt-6">
                        {{ formatReportDatesRange() }}
                        <br />
                        <v-select
                          class="mt-4 mb-n2"
                          v-model="selected"
                          label="Please select data to include"
                          solo
                          :items="optionsData"
                        >
                          <!-- <option value="2">Sites and Inverters</option>
                          <option value="0">Sites Only</option>
                          <option value="1">Inverters Only</option> -->
                        </v-select>
                        <v-select
                          class="mb-n8"
                          v-model="selectedStep"
                          label="Please select time increment"
                          solo
                          :items="optionsTime"
                        >
                          <!-- <option value="">Please select time increment</option>
                          <option value="month">Monthly</option>
                          <option value="day">Daily</option> -->
                        </v-select>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="black" outlined @click="clearSitesCSVExport">
                          Clear
                        </v-btn>
                        <v-btn color="black" outlined @click="closeSitesCSVExport">
                          Close
                        </v-btn>
                        <v-btn
                          elevation="0"
                          :ripple="false"
                          class="
                            font-weight-bold
                            btn-ls btn-primary
                            bg-gradient-primary
                            py-3
                            px-6
                          "
                          @click="exportSitesCSVReport"
                        >
                          Export
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    @click="startValidateWizard()"
                    v-show="high || medium"
                    :disabled="validateDisabled"
                    :elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-xs
                      btn-success
                      bg-gradient-success
                      py-3
                      px-6
                      ms-3
                    ">Validate</v-btn>
                </v-toolbar>
              </template>

              <template v-slot:header.ghi="{ header }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>Measured sun for this date</span>
                </v-tooltip>
              </template>

              <template v-slot:header.precipitation_rate="{ header }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{ header.text }}</span>
                  </template>
                  <span>Precipitation and/or snow cover for this date</span>
                </v-tooltip>
              </template>

              <template v-slot:item.name="{ item }">
                <div class="d-flex align-center ms-2" >
                    <router-link class="py-auto" :to="'/sites/' + item.id">
                      <span class="text-sm font-weight-normal text-body" style="text-decoration: none;">
                      {{ item.name }}
                      </span>
                    </router-link>
                </div>
              </template>

              <template v-slot:item.thirty_day_health="{ item }">
                <v-progress-linear :value="item.thirty_day_health"  style="width: 100px" height="15" :background-color="progressBackground(item.thirty_day_health)" :color="progressBarColor((item.thirty_day_health))" >
                    <div class="text-center">{{ Math.ceil(item.thirty_day_health) }}%</div>
                  </v-progress-linear>
              </template>
              
              <!-- <template v-slot:item.day_health="{ item }">
                <div class="site-wider-slot">
                <v-chip :color="getColor(item.day_health)" dark>
                  {{ Math.round(item.day_health) }}
                </v-chip>
                </div>
              </template> -->
              <template v-slot:item.day_health="{ item }">
                <div class="site-wider-slot">
                  <template v-if="item.has_cases !== 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip :color="getColor(item.day_health)" dark v-bind="attrs" v-on="on">
                          {{ Math.round(item.day_health) }}
                          <!-- Badge rendering when there are cases -->
                          <span class="badge-dot"></span>
                        </v-chip>
                      </template>
                      <span>Cases found, health adjusted by {{ formatNumber(item.case_pct_of_dc_size_kw) }} percent</span>
                    </v-tooltip>
                  </template>

                  <template v-else>
                    <v-chip :color="getColor(item.day_health)" dark>
                      {{ Math.round(item.day_health) }}
                    </v-chip>
                  </template>
                </div>
              </template>
              <!-- sunshine -->
              <template v-slot:item.ghi="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <img
                      :src="
                        require('@/assets/img/' + sunshineVal(item.ghi) + '.png')
                      "
                      style="width:25px; height:25px;"
                      v-if="item.ghi"
                      v-on="on"
                    />
                  </template>
                  <span>{{ sunshinePopupVal(item.ghi) }}</span>
                </v-tooltip>
                <!-- <template>
                  <v-icon v-if="!item.ghi" disabled>mdi-web-box</v-icon>
                </template> -->
              </template>
              <!-- precipitation -->
              <template v-slot:item.precipitation_rate="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <img
                      :src="
                        require('@/assets/img/' + precipVal(item) + '.png')
                      "
                      style="width:25px; height:25px;"
                      
                      v-on="on"
                    />
                  </template>
                  <span>{{ precipPopupVal(item) }}</span>
                </v-tooltip>
                <!-- <template>
                  <v-icon v-if="!item.precip_value" disabled>mdi-web-box</v-icon>
                </template> -->
              </template>
              <!-- Inverter Issue -->
              <template v-slot:item.controls="{item}">
                <div v-if="item.inv_issue" style="color: red; padding-top: 15px; padding-left: 15px;">
                  <span class="material-icons" >
                    warning_amber
                  </span>
                </div>  
              </template>
              <template v-slot:item.energy_yield_var="{item}">
                {{ (item.energy_yield_var*100).toFixed(2) }}%
              </template>
              <!-- <template v-slot:item.actions="{ item }">
                <v-btn
                  v-show="user.claims.user_role == 'super_user'"
                  @click="showWizard(item)"
                  icon
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="btn-ls me-2 my-2 rounded-sm"
                  color="#67748e"
                >
                  <v-icon size="14" class="material-icons-round"
                    >construction</v-icon
                  >
                </v-btn>
              </template> -->
              <!-- native platform link icon -->
              <template v-slot:item.monitoring_url="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <img
                      :src="
                        require('@/assets/img/' + item.monitoring_system + '.png')
                      "
                      v-if="item.monitoring_url"
                      v-on="on"
                      v-on:click.stop.prevent="openWindow(item.monitoring_url)"
                    />
                  </template>
                  <span>Monitoring Link</span>
                </v-tooltip>
                <template>
                  <v-icon v-if="!item.monitoring_url" disabled>mdi-web-box</v-icon>
                </template>
              </template>
              <!-- expand menu option -->
              <template v-slot:item.dots_menu="{ item }">
                <v-menu
                  transition="slide-y-transition"
                  offset-y
                  offset-x
                  min-width="300"
                  max-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :ripple="false"
                      class="text-body"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      <v-icon class="material-icons-round" size="20">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-3">
                    <v-list-item
                      class="
                        pa-4
                        list-item-hover-active
                        d-flex
                        align-items-center
                        py-1
                        my-1
                        border-radius-md
                      "
                      @click="openWindow(item.site_plans)"
                    >
                      <v-icon
                        id="main_prof"
                        class="material-icons-round text-body"
                        size="20"
                        >mdi-floor-plan</v-icon
                      >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                        >
                          <v-row>
                            <v-col>
                              <h6
                                class="
                                  text-sm
                                  font-weight-normal
                                  ms-2
                                  ma-auto
                                  text-typo
                                "
                              >
                                View Site Plans
                              </h6>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item
                      v-if="user.claims.user_role != 'customer_view'"
                      @click="sendEmail(item)"
                      class="
                        pa-4
                        list-item-hover-active
                        d-flex
                        align-items-center
                        py-1
                        my-1
                        border-radius-md
                      "
                    >
                      <v-icon
                        id="logout"
                        class="material-icons-round text-body"
                        size="20"
                        >mdi-email</v-icon
                      >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                        >
                          <v-row>
                            <v-col>
                              <h6
                                class="
                                  text-sm
                                  font-weight-normal
                                  ms-2
                                  ma-auto
                                  text-typo
                                "
                              >
                                Send Email
                              </h6>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item
                      @click="startNotes(item.id)"
                      class="
                        pa-4
                        list-item-hover-active
                        d-flex
                        align-items-center
                        py-1
                        my-1
                        border-radius-md
                      "
                    >
                      <v-icon
                        id="logout"
                        class="material-icons-round text-body"
                        size="20"
                        >mdi-note-multiple</v-icon
                      >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                        >
                          <v-row>
                            <v-col>
                              <h6
                                class="
                                  text-sm
                                  font-weight-normal
                                  ms-2
                                  ma-auto
                                  text-typo
                                "
                              >
                                View Notes
                              </h6>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="startCases(item.id)"
                      class="
                        pa-4
                        list-item-hover-active
                        d-flex
                        align-items-center
                        py-1
                        my-1
                        border-radius-md
                      "
                    >
                      <v-icon
                        id="logout"
                        class="material-icons-round text-body"
                        size="20"
                        >mdi-briefcase</v-icon
                      >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title
                          class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                        >
                          <v-row>
                            <v-col>
                              <h6
                                class="
                                  text-sm
                                  font-weight-normal
                                  ms-2
                                  ma-auto
                                  text-typo
                                "
                              >
                                View Cases
                              </h6>
                            </v-col>
                          </v-row>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <datatable-inverters
                    v-bind:site_id="item.id"
                    v-bind:site="item"
                    v-bind:componentProps="componentProps"
                  ></datatable-inverters>
                </td>
              </template>
            </v-data-table>
            <!-- <site-wizard v-model="show" v-bind:wizardData="wizardData"></site-wizard> -->
          </v-card-text>
          <v-card-actions class="card-padding">
            <v-row>
              <v-col cols="6" lg="3" class="d-flex align-center">
                <span class="text-body me-3 text-sm">Items per page:</span>
                <v-text-field
                  hide-details
                  type="number"
                  outlined
                  min="-1"
                  max="15"
                  background-color="rgba(255,255,255,.9)"
                  color="rgba(0,0,0,.6)"
                  light
                  :value="itemsPerPage"
                  @input="updateItemsPerPage"
                  placeholder="Items per page"
                  class="
                    font-size-input
                    placeholder-lighter
                    text-color-light
                    input-alternative input-focused-alternative input-icon
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="ml-auto d-flex justify-end">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#D81B60"
                  v-model="page"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
        <!-- modals -->
        <notes-modal
          v-if="showNotesModal"
          v-model="showNotesModal"
          v-bind:site_id="this.selectedSite"
        ></notes-modal>
        <cases-modal
          v-if="showCasesModal"
          v-model="showCasesModal"
          v-bind:site_id="this.caseSite"
        ></cases-modal>
        <!-- <site-wizard 
          @close="closeSiteWizard"
          v-model="showSiteWizard"
        >
        </site-wizard> -->
        <site-wizard v-model="show" @close="closeSiteWizard" v-bind:wizardData="wizardData"></site-wizard>
        <validate-wizard @close="closeValidateWizard" 
          v-model="showValidateWizard" 
          v-bind:date="componentProps.date" 
          v-bind:userId="this.$store.getters.currentUser.id"
          v-bind:checkTypeName="`${this.filter}_${this.subFilter}`"
          v-bind:changeFlag="changeFlag">
        </validate-wizard>
      </div>  
</template>
<script>
import SiteWizard from "../SiteWizard.vue";
import DatatableInverters from "./DatatableInverters.vue";
import users from "./Users";
import NotesModal from "./NotesModal.vue";
import CasesModal from "./CasesModal.vue";
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import ValidateWizard from "./ValidateWizard.vue";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  components: { SiteWizard, DatatableInverters, NotesModal, CasesModal, ValidateWizard },
  props: ["checkFrequenciesToDisplay", "componentProps", "filter", "tabChangeFlag"],
  name: "paginated-tables",
  data() {
    return {
      optionsData: [
        {
          text: "Sites & Inverters",
          value: "2",
        },
        { text: "Sites Only", value: "0" },
        { text: "Inverters Only", value: "1" },
      ],
      optionsTime: [
        {
          text: "Daily",
          value: "day",
        },
        {
          text: "Monthly",
          value: "month",
        }
      ],
      subFilter: "high",
      currentItems: [],
      reportDialog: false,
      reportDatesRange: [],
      showNotesModal: false,
      showCasesModal: false,
      selected: null,
      selectedSite: null,
      selectedStep: null,
      caseSite: null,
      changeFlag: {},
      high: false,
      medium: false,
      all: false,
      tab: null,
      toggle_exclusive: 2,
      wizardData: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      expandedIds: [],
      expander: false,
      singleExpand: false,
      show: false,
      showSiteWizard: false,
      showValidateWizard: false,
      componentProps_orig: {},
      dialog: false,
      dialogDelete: false,
      users,
      userId: null,
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          cellClass: "border-bottom",
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          width: 382
        },
        {
          text: "30 Day Health",
          value: "thirty_day_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Health",
          value: "day_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Sun",
          value: "ghi",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Precip.",
          value: "precipitation_rate",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Inverter Issue",
          value: "controls",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        { 
          text: "Sun Variation", 
          value: "energy_yield_var", 
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "30 Day kWh",
        //   value: "thirty_day_kwh",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "30 Day Estimate",
        //   value: "thirty_day_estimate",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "30 Day Health",
        //   value: "thirty_day_health",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        // {
        //   text: "Actions",
        //   value: "actions",
        //   sortable: false,
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "",
          value: "monitoring_url",
        },
        {
          text: "",
          value: "dots_menu",
        },
      ],
    };
  },
  created: function () {
    console.log("datatable create");
    console.log("find ghi", this.componentProps);
    // make a copy
    this.componentProps_orig = Object.assign({}, this.componentProps);
    this.startupData();
  },
  methods: {
    updateItemsPerPage(value) {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        this.itemsPerPage = numValue;
        this.page = 1; // reset page number when changing items per page
      }
    },
    formatNumber(number) {
      if (number === null || number === undefined) {
        // Handle the null or undefined case
        return 0; // or any other default value or action
      }
      return parseFloat(number.toFixed(2));
    },
    startupData() {
      // TODO: refactor this... 
      // this.filterHighAlert();
      // this.filterMediumAlert();
      this.filterAll();
    },  
    customSearch(value, search, item) {
      var nameFound = (((item.name) && (item.name.toLowerCase().includes(search.toLowerCase()))) ? true : false)
      var accountFound = (((item.account_name) && (item.account_name.toLowerCase().includes(search.toLowerCase()))) ? true : false)
      var systemFound = (((item.monitoring_system) && (item.monitoring_system.toLowerCase().includes(search.toLowerCase()))) ? true : false)
      var customerFound = (((item.customer_name) && (item.customer_name.toLowerCase().includes(search.toLowerCase()))) ? true : false)
      return (nameFound || accountFound || systemFound || customerFound);
    },
    closeSitesCSVExport() {
      this.reportDialog = false;
      this.reportDatesRange = [];
      this.itemsPerPage = 10;
    },
    expandPage() {
      this.itemsPerPage = -1;
    },
    expandAll() {
      this.expander = !this.expander;
      if(this.expander) {
          this.expandedIds = this.currentItems
      } else {
          this.expandedIds = []
      }
    },
    clearSitesCSVExport() {
      this.reportDatesRange = [];
    },
    compareTime(time1, time2) {
      return new Date(time1) >= new Date(time2); // true if time1 is later
    },
    formatReportDatesRange() {
      if (this.reportDatesRange.length === 2) {
        const [from, until] = this.reportDatesRange;
        return `From ${from}, until ${until}`;
      } else {
        return "";
      }
    },
    exportSitesCSVReport() {
      const [from, until] = this.reportDatesRange;
      let ids = [];
      if (!this.compareTime(until, from)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        console.log("invalid date selection");
        return;
      }
      // If we have something in the search filter, then we'll grab the sites based on that filter.
      // Otherwise we use the computed function - sitesToDisplay
      ids = this.currentItems.map((site) => site.id);

      var params = {
        from: from,
        until: until,
        sites: ids,
        selectedInclude: this.selected,
        selectedStep: this.selectedStep,
      };
      Service.exportSitesCSVReportFiltered(params)
        .then((response) => {
          Vue.$toast.success(`Report submitted in queue`, {
            position: "top-right",
            duration: 5000,
          });
          Vue.$toast.success(`Please check email in a few minutes`, {
            position: "top-right",
            duration: 5000,
            queue: true,
          });
          // close modal
          this.closeSitesCSVExport();
        })
        .catch((error) => {
          Vue.$toast.error(`Error exporting report`, { position: "top-right" });
          console.error(error);
        });
    },
    getFiltered(e) {
      this.currentItems = e;
    },
    calcSunVariation(day, thirty) {
      let pct = (Math.abs(day)/Math.abs(thirty));
      return pct;
    },
    closeSiteWizard() {
      this.show = false;
    },
    // startSiteWizard() {
    //   // showWizard()
    //   this.showSiteWizard = true;
    // },
    closeValidateWizard(value) {
      console.log(`close validate wizard`);
      this.showValidateWizard = false;
    },
    startValidateWizard() {
      this.showValidateWizard = true;
      this.change();
    },
    showWizard(site) {
      // grab inverters
      const inverters = this.componentProps_orig.inverter_metrics.filter((i) => {
          return (i.site_id == site.id);
      });
      console.log(`site_id: ${site.id}`);
      this.wizardData = {
        site: Object.assign({}, site),
        inverters: [...inverters]
      };
      this.show = true;
    },
    highAlert() {
      console.log('high alert');
      if(!this.high) {
        // disable others
        this.itemsPerPage = 100;
        this.medium = false;
        this.all = false;
        this.high = true;
        this.filterHighAlert();
      } else {
        this.high = false;
        this.filterAll();
      }
    },
    filterHighAlert() {
      this.subFilter = "high";
    },
    // getDailyBadge() {
    //   const response = this.componentProps_orig.daily_high.length
    //   const respond = this.componentProps_orig.daily_medium.length
    //   const response = highDaily
    //   const respond = mediumDaily
    //   this.$emit('high-message', response)
    //   this.$emit('med-message', respond)
    // },
    validateHigh() {
      this.userId = this.$store.getters.currentUser.id;
      let check_type_name = `${this.filter}_${this.subFilter}`;

      Service.createCheck({
        date: this.componentProps.date,
        user_id: this.userId,
        check_type_name: check_type_name
      })
        .then((response) => {
          // need to send message to reload main dashboard
          const payload = response.data;
          this.$store.dispatch('addToChecks', payload);
          this.dialog = false;
          Vue.$toast.success(`High Alert Validated`, {
            position: "top-right",
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error Validating High Alert Sites`, {
            position: "top-right",
          });
          console.error(error);
        })
    },
    change() {
      let id = this.makeid(10);
      this.changeFlag = Object.assign({}, id);
    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    validateMedium() {
      this.userId = this.$store.getters.currentUser.id;
      let check_type_name = `${this.filter}_${this.subFilter}`;
      Service.createCheck({
        date: this.componentProps.date,
        user_id: this.userId,
        check_type_name: check_type_name
      })
        .then((response) => {
          const payload = response.data;
          this.$store.dispatch('addToChecks', payload);
          this.dialog = false;
          Vue.$toast.success(`Medium Alert Validated`, {
            position: "top-right",
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error Validating Medium Alert Sites`, {
            position: "top-right",
          });
          console.error(error);
        })
    },
    mediumAlert() {
      console.log('medium alert');
      if(!this.medium) {
        // disable others
        this.itemsPerPage = 100;
        this.high = false;
        this.all = false;
        this.medium = true;
        this.filterMediumAlert();
      } else {
        this.medium = false;
        this.filterAll();
      }
    },
    filterMediumAlert() {
      this.subFilter = "medium";
    },
    allSites() {
      if(!this.all) {
        // disable others
        this.high = false;
        this.medium = false;
        this.all = true;
        this.itemsPerPage = 10;
        this.filterAll();
      } else {
        this.all = false;
      }
    },
    filterAll() {
      this.subFilter = null;
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    getColor(percentage) {
      if (percentage < 75) {
        return "red";
      } else if (percentage < 90) {
        return "orange";
      } else {
        return "green";
      }
    },
    sunshineVal(ghi) {
      if (ghi >= 5300) {
        return 'Sunny'
      } else if (ghi >= 3000) {
        return 'Partly_Cloudy'
      } else if (ghi >= 1500) {
        return 'Mostly_Cloudy'
      } else {
        return 'Heavy_Clouds'
      }
    },
    sunshinePopupVal(ghi) {
      if (ghi >= 5300) {
        return 'Sunny'
      } else if (ghi >= 3000) {
        return 'Mostly Sunny'
      } else if (ghi >= 1500) {
        return 'Partly Cloudy'
      } else {
        return 'Very Cloudy'
      }
    },
    precipVal(item) {
      if (item.snow_soiling_rooftop >= 15) {
        return 'heavy_snow'
      } else if (item.snow_soiling_rooftop > 0) {
        return 'light_snow'
      } else {
        if (item.precipitation_rate >= 25) {
          return 'torrential_rain'
        } else if (item.precipitation_rate > 0) {
          return 'light_rain'
        } else {
          return blank
        }
      }
    },
    precipPopupVal(item) {
      if (item.snow_soiling_rooftop >= 15) {
        return 'Heavy Snow'
      } else if (item.snow_soiling_rooftop > 0) {
        return 'Light Snow'
      } else {
        if (item.precipitation_rate >= 25) {
          return 'Torrential Rain'
        } else if (item.precipitation_rate > 0) {
          return 'Light Rain'
        } else {
          return null
        }
      }
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
    progressBarColor(percentage) {
      let color = "";

      if (percentage < 75) {
        color = "red";
      } else if (percentage < 90) {
        color = "orange";
      } else {
        color = "green";
      }

      return color;
    },
    progressBackground(percentage) {
      if(percentage <= 0) {
        return "red";
      } else {
        return "grey lighten-2";
      }
    },
    sendEmail(item) {
      var email = item.email;
      var subject = "An email about " + item.name + " Solar Array";
      var emailBody = "Some message here";
      document.location = "mailto:" + email + "?subject=" + subject;
    },
    startNotes(site_id) {
      this.selectedSite = site_id;
      this.showNotesModal = true;
    },
    startCases(site_id) {
      this.caseSite = site_id;
      this.showCasesModal = true;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
  },
  watch: {
    tabChangeFlag: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      console.log('reset values...');
      // this.componentProps = Object.assign({}, this.componentProps_orig);
      this.medium = false;
      this.all = true;
      this.itemsPerPage = 10;
      this.high = false;
      // reset to all sites on tab change
      this.filterAll();
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
    validateDisabled() {
      if(this.filter !== 'all') {
        if(this.subFilter) {
          let f = `${this.filter}_${this.subFilter}`;
          let has_check = this.$store.state.alerts.checks.find((c) => 
            c.date == this.componentProps.date && c.check_type_name == f
          );
          if(has_check) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    sites() {
      let f = 'all';
      if(this.filter !== 'all') {
        if(this.subFilter) {
          f = `${this.filter}_${this.subFilter}`;
        } else {
          f = `${this.filter}`;
        }
      }
      
      if (this.componentProps && this.componentProps[f]) {
        if(f == 'all') {
          return this.componentProps[f];
        } else {
          const ps = this.componentProps[f].filter((s) => {
            return this.checkFrequenciesToDisplay.includes(s.check_frequency) 
          });
          return ps;
        }
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
    c() {
      let f = 'all';
      if(this.filter !== 'all') {
        if(this.subFilter) {
          f = `${this.filter}_${this.subFilter}`;
        } else {
          f = `${this.filter}`;
        }
      }
      
      if (this.componentProps && this.componentProps[f]) {
        if(f == 'all') {
          return this.componentProps[f].length;
        } else {
          return this.componentProps[f].length;
        }
      }
    }
  },
};
</script>
<style>
  .v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none !important;
  }
  #button_s {
    float: left;
  }
  .site-wider-slot {
    padding-left: 10px;
    /* width: 300px; 
    padding-right: 0px; 
    margin-left: 0px;  */
  }
  .badge-dot {
    height: 6px;
    width: 6px;
    background-color: rgb(0, 0, 0); /* Red dot, change color as needed */
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
  }

</style>