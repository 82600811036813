var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "50%" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" Site Notes ")]),
          _c(
            "v-card-text",
            [
              [
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    { staticClass: "row mb-3" },
                    [
                      [
                        _c(
                          "form",
                          {
                            staticClass: "col-12",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.addNote()
                              },
                            },
                          },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Create a new note..." },
                              model: {
                                value: _vm.newNote,
                                callback: function ($$v) {
                                  _vm.newNote = $$v
                                },
                                expression: "newNote",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "list-group",
                          staticStyle: { "padding-left": "0px" },
                        },
                        _vm._l(_vm.notes, function (note) {
                          return _c("note", {
                            attrs: {
                              description: note.note,
                              date: note.date,
                              id: note.id,
                            },
                            on: {
                              "on-toggle": function ($event) {
                                return _vm.toggleNote(note)
                              },
                              "on-delete": function ($event) {
                                return _vm.deleteNote(note)
                              },
                              "on-edit": function ($event) {
                                return _vm.editNote(note, $event)
                              },
                            },
                          })
                        }),
                        1
                      ),
                    ]),
                  ]),
                ]),
              ],
            ],
            2
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n          font-weight-bold\n          text-xs\n          btn-default\n          bg-gradient-danger\n          shadow-danger\n        ",
                  attrs: { ripple: false, elevation: 0 },
                  on: {
                    click: function ($event) {
                      return _vm.closeNotesModal()
                    },
                  },
                },
                [_vm._v("Close Notes")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }