<template>
  <v-card
    class="card-shadow border-radius-xl px-4 py-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div 
      class="
        bg-gradient-success
        shadow-success
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
          :height="$route.name == 'VrDefault' ? '370' : '170'"
          :id="chartID"
        >
        </canvas>
      </div>
    </div>

    <h6
      class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
      :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'"
    >
      Site Performance
    </h6>
    <p class="text-sm ms-2 text-body font-weight-light">
      Daily Performance Metrics
    </p>
    <hr class="horizontal dark mb-4 mx-2" />
    <div v-if="loading">
     
          <v-progress-circular
                    indeterminate
                  ></v-progress-circular>
    </div>
    <div v-else class="d-flex text-body mx-2">
      <i class="material-icons-round text-sm my-auto me-1">schedule</i>
      <!-- TODO: update this timestamp -->
      <p class="mb-0 text-sm font-weight-light text-body">just updated</p>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";
import Service from "@/services/Service.js";

export default {
  props: {
    metricsEndDateMoment: Object,
  },
  name: "card-thirty-day-perf",
  data: function () {
    return {
      loading: false,
      chartID: "perfChartID",
    };
  },
  created: function () {
    // this.metricsEndDateMoment = this.$moment().subtract(1, "days");
    this.getData();
  },
  mounted() {

  },
  methods: {
    getData() {
      const dateString = this.metricsEndDateMoment.format("YYYY-MM-DD");
      this.loading = true;
      
      Service.getDailyPerformanceSum(dateString)
        .then((response) => {
          const site_metrics = response.data.daily_performance_sum;
          const labels = site_metrics.map((m) => {
            var d = new Date(`${m.date} 00:00:00`);
            return `${d.getMonth()+1}/${d.getDate()}`;
          });

          const lineData = site_metrics.map((m) => {
            return m.performance.toFixed(2);
          });

          

          //console.log(this.data);
          // that.data.datasets = this.getNewDataSet(line_data);
          const ctx = document.getElementById(this.chartID).getContext("2d");
          const chart = this.getNewChart(ctx, labels, lineData);
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting metric data:", error.response);
          this.loading = false;
        });
    },
    getNewChart(ctx, labels, lineData) {
      return new Chart(document.getElementById(this.chartID).getContext("2d"), {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Performance",
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 4,
              borderSkipped: false,
              backgroundColor: "rgba(255, 255, 255, .8)",
              data: lineData,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                suggestedMin: 0,
                suggestedMax: 500,
                beginAtZero: true,
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
                color: "#fff",
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Roboto",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    }
  },
};
</script>
