import { render, staticRenderFns } from "./CasesModal.vue?vue&type=template&id=02cfc6ec&scoped=true&"
import script from "./CasesModal.vue?vue&type=script&lang=js&"
export * from "./CasesModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02cfc6ec",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog,VSpacer,VTab,VTabItem,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('02cfc6ec')) {
      api.createRecord('02cfc6ec', component.options)
    } else {
      api.reload('02cfc6ec', component.options)
    }
    module.hot.accept("./CasesModal.vue?vue&type=template&id=02cfc6ec&scoped=true&", function () {
      api.rerender('02cfc6ec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Applications/Widgets/CasesModal.vue"
export default component.exports