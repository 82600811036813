<template>
  <v-dialog v-model="show" width="1200">
    <v-card tile>
      <v-toolbar flat :color="'grey darken-3'">
        <v-toolbar-title style="color: white"
          >Validate Site Wizard</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Verify Online Sites
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Verify Site Data
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> Finish</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-card-text>
                  <online-sites v-bind:changeFlag="changeFlag"></online-sites>
                </v-card-text>
              </v-card>
              <div class="text-end">
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-2
                    mb-2
                    me-2
                  "
                  color="primary"
                  @click="e1 = 2"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="mb-12">
                <v-card-text>
                  <v-container fluid>
                    <v-checkbox
                      v-model="ack"
                      :disabled="checkDisabled"
                      @click="acknowledge()"
                      :label="`I checked all of these ${getCheckFreq()}, ${getCheckLevel()} alert sites for ${this.date}, and opened a case if needed.`"
                    ></v-checkbox>
                  </v-container>
                </v-card-text>
              </v-card>
              <div class="d-flex mt-10">
                <v-btn
                  :elevation="0"
                  style="padding-left: 5px"
                  class="
                    font-weight-bold
                    text-xs text-dark
                    shadow-none
                    bg-transparent
                    btn-outline-secondary
                    py-5
                    px-6
                    mt-6
                    mb-2
                    ms-2
                  "
                  @click="e1 = 1"
                >
                  Prev
                </v-btn>

                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-6
                    mb-2
                    me-2
                    ms-auto
                  "
                  color="primary"
                  @click="e1 = 3"
                >
                  Continue
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card
                class="mb-12"
              >
              <v-card-text>
                Please click the Finish button to complete the validation process.
              </v-card-text>
              </v-card>
              <div class="d-flex">
                <v-btn
                  :elevation="0"
                  class="
                    font-weight-bold
                    text-xs text-dark
                    btn-light
                    shadow-none
                    bg-transparent
                    btn-outline-secondary
                    py-5
                    px-6
                    mt-2
                    mb-2
                    ms-2
                  "
                  @click="e1 = 2"
                >
                  Prev
                </v-btn>
                <v-btn
                  :elevation="0"
                  @click="close()"
                  class="
                    font-weight-bold
                    text-xs
                    btn-default
                    bg-gradient-default
                    py-5
                    px-6
                    mt-2
                    mb-2
                    me-2
                    ms-auto
                  "
                  color="primary"
                >
                  Finish
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Service from "@/services/Service.js";
import OnlineSites from "@/views/admin/OnlineSites.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
Vue.use(VueToast);

export default {
  name: "ValidateWizard",
  components: {
    OnlineSites,
  },
  props: {
    value: Boolean,
    date: String,
    userId: Number,
    checkTypeName: String,
    changeFlag: Object,
  },
  data() {
    return {
      e1: 1,
      ack: false,
      toggle_exclusive: 2,
      siteApiLoading: false,
      siteApiStatus: false,
    };
  },
  created: function () {
    console.log("validate-wizard create");
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        // console.log(`set...${value}`)
        this.$emit("close", value);
      },
    },
    checkDisabled() {
      let f = this.checkTypeName;
      let has_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.date && c.check_type_name == f
      );
      if(has_check) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getCheckFreq() {
      let parts = this.checkTypeName.split("_")
      return parts[0];
    },
    getCheckLevel() {
      let parts = this.checkTypeName.split("_")
      return parts[1];
    },
    acknowledge() {
      console.log("acknowledgement...");
      this.createCheck();
    },
    close() {
      this.e1 = 1;
      this.ack = false;
      console.log("validate wizard close action...");
      this.$emit("close", true);
    },
    closeWizard() {
      this.value = false;
    },
    createCheck() {
      Service.createCheck({
        date: this.date,
        user_id: this.userId,
        check_type_name: this.checkTypeName
      })
        .then((response) => {
          const payload = response.data;
          this.$store.dispatch('addToChecks', payload);
          this.dialog = false;
          Vue.$toast.success(`Alert Validated`, {
            position: "top-right",
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error Validating Alert Sites`, {
            position: "top-right",
          });
          console.error(error);
        })
    }
  },
  watch: {
    changeFlag: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      console.log("reset values...");
      this.e1 = 1;
      this.ack = false;
    },
  },
};
</script>

<style>
</style>