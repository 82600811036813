<template>
  <v-dialog v-model="show" width="50%">
    <v-card>
      <v-card-title> Site Notes </v-card-title>
      <v-card-text>
        <template>
          <div class="container">
            <div class="row mb-3">
              <template>
                <form class="col-12" @submit.prevent="addNote()">
                  <v-text-field
                    label="Create a new note..."
                    v-model="newNote"
                  ></v-text-field>
                </form>
              </template>
            </div>
            <div class="row">
              <div class="col-12">
                <ul class="list-group" style="padding-left: 0px">
                  <note
                    v-for="note in notes"
                    :description="note.note"
                    :date="note.date"
                    :id="note.id"
                    @on-toggle="toggleNote(note)"
                    @on-delete="deleteNote(note)"
                    @on-edit="editNote(note, $event)"
                  />
                </ul>
              </div>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-danger
            shadow-danger
          "
          @click="closeNotesModal()"
          >Close Notes</v-btn
        >
        <!-- <v-btn color="primary"
              @click="saveNotes()"
               >
          Save
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Service from "@/services/Service.js";
import Note from "@/components/Note";

export default {
  components: {
    Note,
  },
  props: {
    value: Boolean,
    site_id: Number,
  },
  data() {
    return {
      notes: [],
      newNote: "",
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  created: function () {
    this.loadNotes(this.site_id);
  },
  methods: {
    change(e) {
      this.accountValue = { id: e.target.value.id, name: e.target.value.name };
      // this.$emit("change", e, e.target.value);
    },
    closeNotesModal() {
      // this.showUploadSitesModal = false;
      this.show = false;
    },
    addNote() {
      var params = {
        site_id: this.site_id,
        note: this.newNote,
      };
      Service.createNote(this.site_id, params)
        .then((response) => {
          return Service.getNotes(this.site_id);
        })
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error creating site:", error.response);
        });
      this.newNote = "";
    },
    loadNotes(site_id) {
      Service.getNotes(site_id)
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error getting sites data:", error.response);
        });
    },
    deleteNote(deletedNote) {
      Service.deleteNote(this.site_id, deletedNote.id)
        .then((response) => {
          return Service.getNotes(this.site_id);
        })
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error creating site:", error.response);
        });
    },
    editNote(note, newNoteDescription) {
      // note.description = newNoteDescription;
      var params = {
        site_id: this.site_id,
        id: note.id,
        note: newNoteDescription,
      };
      Service.updateNote(this.site_id, note.id, params)
        .then((response) => {
          return Service.getNotes(this.site_id);
        })
        .then((response) => {
          this.notes = response.data;
        })
        .catch((error) => {
          console.log("Error updating note:", error.response);
        });
    },
  },
};
</script>
