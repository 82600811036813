<template>
  <v-dialog v-model="show" width="80%" height="700px">
    <v-card>
      <v-card-text>
        <v-tabs>
          <v-tab @change="listCaseTab">List of site cases</v-tab>
          <v-tab @change="changeTab">Create new case</v-tab>
          <v-tab-item>
            <template>
              <div class="container">
                <div class="row mb-3"></div>
                <div class="row">
                  <div class="col-12">
                    <ul class="list-group" style="padding-left: 0px">
                      <case
                        v-if="cases.length > 0"
                        :sitecases="cases"
                        :loading="caseListLoading"
                        :key="site_id"
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </template>
          </v-tab-item>
          <v-tab-item>
            <new-case-modal
              v-if="(user.claims.user_role == 'super_user' || user.claims.user_role == 'customer_admin') && this.sites.length > 0"
              v-bind:show_switch="visibleDialog"
              :site_id="site_id"
              :edit_item="edit_item"
              @save="save"
              @cancel="handleCancel"
              @close="handleClose"
              @remove="remove"
            ></new-case-modal>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-danger shadow-danger"
          @click="closeCasesModal()"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Service from "@/services/Service.js";
import Case from "@/components/Case";
import Vue from "vue";
import NewCaseModal from "@/views/case/NewCaseModal.vue";

export default {
  components: { 
    Case,
    NewCaseModal,
  },
  props: {
    value: Boolean,
    site_id: Number,
  },
  data() {
    return {
      caseListLoading: true, // add loading state
      address_line_1_edit: null,
      cases: [],
      valid: true,
      sites: [],
      subject_edit: null,
      city: null,
      casestatus: [],
      caseStatusId: null,
      caseStatus: null,
      caseCategoryId: null,
      caseCategory: null,
      casePriorityId: null,
      casePriority: null,
      casecategories: [],
      casepriorities: [],
      city_edit: null,
      contact: null,
      contactId: null,
      contacts: [],
      date_added_edit: null,
      dateAddedVal: null,
      dateClosedVal: null,
      date_closed_edit: null,
      downtime_days_edit: null,
      downtime_pct_edit: null,
      id_edit: null,
      kw_affected_edit: null,
      lost_production_edit: null,
      monitoring_plat_edit: null,
      notes_edit: null,
      showNotesInsideCasesFormModal: false,
      vendor_case_num_edit: null,
      visibleDialog: false,
      edit_item: null,
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.currentUser;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    dateAddedDisp() {
      return this.dateAddedVal;
    },
  },
  created: function () {
    let date = new Date();
    this.date_added_edit = this.$moment(date).format("YYYY-MM-DD");
    this.dateAddedVal = this.$moment(date).format("YYYY-MM-DD");

    this.getData(this.site_id);
  },
  methods: {
    change(e) {
      this.accountValue = { id: e.target.value.id, name: e.target.value.name };
      // this.$emit("change", e, e.target.value);
    },
    listCaseTab() {
      this.getData(this.site_id);
    },
    changeTab() {
      this.visibleDialog = true;
      // window.setTimeout(() => {
      //   this.$refs.newCaseForm.validate();
      // }, 1000);
    },
    closeCasesModal() {
      // this.showUploadSitesModal = false;
      this.show = false;
    },
    getData(site_id) {
      console.log(`loading case data for site_id = ${site_id}`);
      this.caseListLoading = true;
      Service.getCasesandSites(site_id)
        .then((response) => {
          this.cases = response[0].data;
          this.sites = [response[1].data];
          this.caseListLoading = false;
          console.log(this.cases);
        })
        .catch((error) => {
          this.caseListLoading = false;
          console.log("Error getting cases data:", error.response);
        });
    },
    handleCancel() {
      console.log("handleCancel");
      this.visibleDialog = false;
    },
    handleClose() {
      console.log("handleClose");
      // TODO: need some clean up here and reload case list for other tab
      this.edit_item = null;
      this.visibleDialog = false;
    },
    remove(e) {
      console.log(`remove ${e}`);
      Service.deleteCase(e.dataItem.id)
        .then(() => {
          this.edit_item = null;
          this.visibleDialog = false;
          Vue.$toast.warning(`Case removed`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    saveAndClose: function (e, equip) {
      var today = new Date();
      // create if id is null
      if (e.dataItem.id == undefined) {
        Service.createCase(e.dataItem, equip)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case created`, {
              position: "top-right",
              duration: 4000,
            });
            e.dataItem.id = response.data.id;
            // set case id in edit
            // this.id_edit = response.data.id;
            // this.edit_item = e.dataItem;
            // this.edit_item.site = response.data.site;
            this.edit_item = null;
            // this.gridData.splice(index, 1, updated);
            // reload data grid
            // this.getData();
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        if (e.dataItem.case_status.name == "Done") {
          // set closed date
          e.dataItem.date_closed = this.$moment(today).format("YYYY-MM-DD");
        } else {
          // remove downtime_days unless we are closing it out
          delete e.dataItem.downtime_days;
          delete e.dataItem.downtime_numbers;
        }
        Service.updateCase(e.dataItem.id, e.dataItem)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case updated`, {
              position: "top-right",
              duration: 4000,
            });
            const c = response.data;
            e.dataItem.id = response.data.id;
            // this.id_edit = c.id;
            // this.edit_item = e.dataItem;
            // this.edit_item.site = response.data.site;
            this.edit_item = null;
            // this.gridData.splice(index, 1, updated);
            // this.getData();
            if (c.case_status.name == "Done") {
              //this.toggleDialog();
            }
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    save: function (e, equip) {
      var today = new Date();
      // create if id is null
      if (e.dataItem.id == undefined) {
        Service.createCase(e.dataItem, equip)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case created`, {
              position: "top-right",
              duration: 4000,
            });
            e.dataItem.id = response.data.id;
            // set case id in edit
            this.id_edit = response.data.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            // reload data grid
            // this.getData();
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        if (e.dataItem.case_status.name == "Done") {
          // set closed date
          e.dataItem.date_closed = this.$moment(today).format("YYYY-MM-DD");
        } else {
          // remove downtime_days unless we are closing it out
          delete e.dataItem.downtime_days;
          delete e.dataItem.downtime_numbers;
        }
        Service.updateCase(e.dataItem.id, e.dataItem)
          .then((response) => {
            // this.toggleDialog();
            Vue.$toast.success(`Case updated`, {
              position: "top-right",
              duration: 4000,
            });
            const c = response.data;
            e.dataItem.id = response.data.id;
            this.id_edit = c.id;
            this.edit_item = e.dataItem;
            this.edit_item.site = response.data.site;
            // this.gridData.splice(index, 1, updated);
            // this.getData();
            if (c.case_status.name == "Done") {
              //this.toggleDialog();
            }
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    siteSelect(site_id) {
      // update address and city
      const site = this.sites.find((x) => x.id == site_id);
      //   this.site_id = site;
      this.address_line_1_edit = site.address.street;
      this.city_edit = site.address.city;
    },
  },
};
</script>
<style scoped>

</style>