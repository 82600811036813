<template>
  <div>
    <v-progress-circular v-if="loading" indeterminate></v-progress-circular>
    <grid v-else :data-items="items" :columns="columns"> </grid>
  </div>
</template>

<script>
import { Grid } from "@progress/kendo-vue-grid";

export default {
  props: {
    sitecases: Array,
    loading: Boolean,
  },
  components: {
    grid: Grid,
  },
  data: function() {
    return {
      columns: [
        { field: "subject", title: "Subject" },
        { field: "note_message", title: "Notes" },
        { field: "date_added", title: "Date of creation" },
        { field: "case_status.name", title: "Status" }
      ],
      items: [],
    };
  },
  watch: {
    sitecases: {
      immediate: true,
      handler(newVal) {
        this.items = newVal.slice();
        this.sortByDate();
      },
    },
  },
  methods: {
    sortByDate() {
      this.items.sort((a, b) => new Date(b.date_added) - new Date(a.date_added));
      console.log(this.items);
    },
  },
  created() {
    this.sortByDate();
  },
};
</script>
