var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "80%", height: "700px" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-tabs",
                [
                  _c("v-tab", { on: { change: _vm.listCaseTab } }, [
                    _vm._v("List of site cases"),
                  ]),
                  _c("v-tab", { on: { change: _vm.changeTab } }, [
                    _vm._v("Create new case"),
                  ]),
                  _c(
                    "v-tab-item",
                    [
                      [
                        _c("div", { staticClass: "container" }, [
                          _c("div", { staticClass: "row mb-3" }),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "ul",
                                {
                                  staticClass: "list-group",
                                  staticStyle: { "padding-left": "0px" },
                                },
                                [
                                  _vm.cases.length > 0
                                    ? _c("case", {
                                        key: _vm.site_id,
                                        attrs: {
                                          sitecases: _vm.cases,
                                          loading: _vm.caseListLoading,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                    ],
                    2
                  ),
                  _c(
                    "v-tab-item",
                    [
                      (_vm.user.claims.user_role == "super_user" ||
                        _vm.user.claims.user_role == "customer_admin") &&
                      this.sites.length > 0
                        ? _c("new-case-modal", {
                            attrs: {
                              show_switch: _vm.visibleDialog,
                              site_id: _vm.site_id,
                              edit_item: _vm.edit_item,
                            },
                            on: {
                              save: _vm.save,
                              cancel: _vm.handleCancel,
                              close: _vm.handleClose,
                              remove: _vm.remove,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-danger shadow-danger",
                  attrs: { ripple: false, elevation: 0 },
                  on: {
                    click: function ($event) {
                      return _vm.closeCasesModal()
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }