var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    { attrs: { "background-color": "transparent", grow: "" } },
    [
      _c(
        "v-tab",
        {
          on: {
            click: function ($event) {
              return _vm.tabChanged()
            },
          },
        },
        [
          _vm.site_daily_count != 0
            ? _c(
                "v-badge",
                { attrs: { color: "pink", content: _vm.site_daily_count } },
                [_vm._v(" Daily ")]
              )
            : _c("v-badge", [_vm._v(" Daily ")]),
        ],
        1
      ),
      _c(
        "v-tab",
        {
          on: {
            click: function ($event) {
              return _vm.tabChanged()
            },
          },
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "green",
                content: _vm.site_weekly_count,
                value: _vm.site_weekly_count > 0,
              },
            },
            [_vm._v(" Weekly ")]
          ),
        ],
        1
      ),
      _c(
        "v-tab",
        {
          on: {
            click: function ($event) {
              return _vm.tabChanged()
            },
          },
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "deep-purple accent-4",
                content: _vm.site_monthly_count,
                value: _vm.site_monthly_count > 0,
              },
            },
            [_vm._v(" Monthly ")]
          ),
        ],
        1
      ),
      _c(
        "v-tab",
        {
          on: {
            click: function ($event) {
              return _vm.tabChanged()
            },
          },
        },
        [
          _c(
            "v-badge",
            {
              attrs: {
                color: "deep-purple accent-4",
                content: _vm.site_count,
                value: _vm.site_count > 0,
              },
            },
            [_vm._v(" All ")]
          ),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("datatable-sites", {
            attrs: {
              checkFrequenciesToDisplay: _vm.checkFrequenciesToDisplay,
              componentProps: _vm.componentProps,
              filter: _vm.checkFreqFilterDaily,
              tabChangeFlag: _vm.tabChangeFlag,
            },
            on: {
              "high-message": _vm.changeHigh,
              "med-message": _vm.changeMedium,
            },
          }),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("datatable-sites", {
            attrs: {
              checkFrequenciesToDisplay: _vm.checkFrequenciesToDisplay,
              componentProps: _vm.componentProps,
              filter: _vm.checkFreqFilterWeekly,
              tabChangeFlag: _vm.tabChangeFlag,
            },
          }),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("datatable-sites", {
            attrs: {
              checkFrequenciesToDisplay: _vm.checkFrequenciesToDisplay,
              componentProps: _vm.componentProps,
              filter: _vm.checkFreqFilterMonthly,
              tabChangeFlag: _vm.tabChangeFlag,
            },
          }),
        ],
        1
      ),
      _c(
        "v-tab-item",
        [
          _c("datatable-sites", {
            attrs: {
              checkFrequenciesToDisplay: _vm.checkFrequenciesToDisplay,
              componentProps: _vm.componentProps,
              filter: _vm.checkFreqFilterAll,
              tabChangeFlag: _vm.tabChangeFlag,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }