<template>
  <v-tabs
      background-color="transparent"
      grow
    >
    <v-tab @click="tabChanged()">
      <v-badge
        v-if="site_daily_count != 0"
        color="pink"
        :content="site_daily_count"
      >
        Daily
      </v-badge>
      <v-badge
        v-else
      >
        Daily
      </v-badge>
    </v-tab>

<!-- :content="site_weekly_count" -->
    <v-tab @click="tabChanged()">
      <v-badge
        color="green"
        :content="site_weekly_count"
        :value="site_weekly_count > 0"
      >
        Weekly
      </v-badge>
    </v-tab>

    <v-tab @click="tabChanged()">
      <v-badge
        color="deep-purple accent-4"
        :content="site_monthly_count"
        :value="site_monthly_count > 0"
      >
        Monthly
      </v-badge>
    </v-tab>
    <v-tab @click="tabChanged()">
      <v-badge
        color="deep-purple accent-4"
        :content="site_count"
        :value="site_count > 0"
      >
        All
      </v-badge>
    </v-tab>
    <v-tab-item>
      <!-- daily -->
      <datatable-sites @high-message="changeHigh" @med-message="changeMedium" v-bind:checkFrequenciesToDisplay="checkFrequenciesToDisplay" v-bind:componentProps="componentProps" v-bind:filter="checkFreqFilterDaily" v-bind:tabChangeFlag="tabChangeFlag"></datatable-sites>
    </v-tab-item>
    <v-tab-item>
      <!-- weekly -->
      <datatable-sites v-bind:checkFrequenciesToDisplay="checkFrequenciesToDisplay" v-bind:componentProps="componentProps" v-bind:filter="checkFreqFilterWeekly" v-bind:tabChangeFlag="tabChangeFlag"></datatable-sites>
    </v-tab-item>
    <v-tab-item>
      <!-- monthly -->
      <datatable-sites v-bind:checkFrequenciesToDisplay="checkFrequenciesToDisplay" v-bind:componentProps="componentProps" v-bind:filter="checkFreqFilterMonthly" v-bind:tabChangeFlag="tabChangeFlag"></datatable-sites>
    </v-tab-item>
    <v-tab-item>
      <!-- all -->
      <datatable-sites v-bind:checkFrequenciesToDisplay="checkFrequenciesToDisplay" v-bind:componentProps="componentProps" v-bind:filter="checkFreqFilterAll" v-bind:tabChangeFlag="tabChangeFlag"></datatable-sites>
    </v-tab-item>
  </v-tabs>
  
</template>

<script>
import DatatableSites from '../../Applications/Widgets/DatatableSites.vue'
export default {
  components: { DatatableSites },
  props: ['checkFrequenciesToDisplay', 'componentProps'],
  name: "site-container",
  data() {
    return {
      currentDate: null,
      checkFreqFilterDaily: 'daily',
      checkFreqFilterWeekly: 'weekly',
      checkFreqFilterMonthly: 'monthly',
      checkFreqFilterAll: 'all',
      checked: false,
      tabChangeFlag: {},
      highCount: null,
      mediumCount: null
    }
  },
  created: function() {
    // debugger;
    console.log(this.$store.state.checks);
    // date is in componentProps.date
    // this is the selected date on the main dashboard
  },
  computed: {
    site_daily_count: function() {
      // Use vuex store here to determine what has been checked so far
      // The check data gets loaded when the dashboard gets created()
      let count = 0
      // check high
      let has_daily_high_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "daily_high"
      );
      if(!has_daily_high_check) {
        count = count += this.componentProps.daily_high.length;
      }
      // check medium
      let has_daily_medium_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "daily_medium"
      );
      if(!has_daily_medium_check) {
        count = count += this.componentProps.daily_medium.length;
      }
      return count;
    },
    site_count: function() {
      // here we apply no filter for ALL sites
      if(this.componentProps && this.componentProps[this.checkFreqFilterAll]) {
        return this.componentProps[this.checkFreqFilterAll].length;
      } else {
        return 0;
      }
    },
    site_weekly_count: function() {
      let count = 0
      // check high
      let has_weekly_high_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "weekly_high"
      );
      if(!has_weekly_high_check) {
        count = count += this.componentProps.weekly_high.length;
      }
      // check medium
      let has_weekly_medium_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "weekly_medium"
      );
      if(!has_weekly_medium_check) {
        count = count += this.componentProps.weekly_medium.length;
      }

      // determine if correct day of week to show badge
      if(this.componentProps && this.componentProps[this.checkFreqFilterWeekly]) {
        // filter first by calculated check frequency
        const ps = this.componentProps[this.checkFreqFilterWeekly].filter((s) => {
          return this.checkFrequenciesToDisplay.includes(s.check_frequency) 
        });
        // 
        if(ps.length > 0) {
          // we are on the weekly day... show badge of high and medium sites that have not been checked
          return count;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    site_monthly_count: function() {
      let count = 0
      // check high
      let has_monthly_high_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "monthly_high"
      );
      if(!has_monthly_high_check) {
        count = count += this.componentProps.monthly_high.length;
      }
      // check medium
      let has_monthly_medium_check = this.$store.state.alerts.checks.find((c) => 
        c.date == this.componentProps.date && c.check_type_name == "monthly_medium"
      );
      if(!has_monthly_medium_check) {
        count = count += this.componentProps.monthly_medium.length;
      }

      // return this.problem_sites.length;
      if(this.componentProps && this.componentProps[this.checkFreqFilterMonthly]) {
        // filter first by calculated check frequency
        const ps = this.componentProps[this.checkFreqFilterMonthly].filter((s) => {
          return this.checkFrequenciesToDisplay.includes(s.check_frequency) 
        });
        if(ps.length > 0) {
          return count
        } else {
          return 0;
        }
      } else {
        return 0;
      }
      //   const x = ps.filter((s) => s.check_frequency == 'monthly' );
      //   return x.length;
      // } else {
      //   return 0;
      // }
    },
  },
  methods: {
    tabChanged() {
      let id = this.makeid(10);
      this.tabChangeFlag = Object.assign({}, id);
    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    changeHigh(e) {
      this.highCount = e;
    },
    changeMedium(e) {
      this.mediumCount = e;
    }
  },
}
</script>

<style>

</style>