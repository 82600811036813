<template>
  <v-card class="mb-6 card-shadow border-radius-xl py-4">
    <v-row no-gutters class="px-4">
      <v-col sm="4">
        <v-avatar
          color="bg-gradient-success border-radius-xl mt-n8"
          class="shadow-success"
          height="64"
          width="64"
        >
          <v-icon class="material-icons-round text-white" size="24"
            >sync_problem</v-icon
          >
        </v-avatar>
      </v-col>
      <v-col sm="8" class="text-end">
        <p
          class="
            text-sm
            mb-0
            text-capitalize text-body
            font-weight-light
          "
        >
          Missing Kwh stats
        </p>
        <h4 v-if="loading">
          <v-progress-circular
                    indeterminate
                  ></v-progress-circular>
        </h4>
        <h4 v-else class="text-h4 text-typo font-weight-bolder mb-0">
          {{missing_kwh_count}}
        </h4>
      </v-col>
    </v-row>
    <hr class="dark horizontal mt-3 mb-4" />
    <v-row class="px-4">
      <v-col cols="12">
        <p class="mb-0 text-body">
          <span class="text-success text-sm font-weight-bolder"
            >+1%</span
          >
          <span class="font-weight-light ms-1">than yesterday</span>
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";
import Service from "@/services/Service.js";

export default {
  props: {
    metricsEndDateMoment: Object,
  },
  name: "card-missing-kwh-data",
  data: function () {
    return {
      loading: false,
      missingKwhData: []
    };
  },
  computed: {
    missing_kwh_count: function() {
      if(this.missingKwhData) {
        return this.missingKwhData.length;
      } else {
        return 0;
      }
    },
    chartData: function() {
      return this.data;
    }
  },
  watch: { 
    metricsEndDateMoment: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      console.log('reloading metrics...');
      this.getData();
    }
  },
  created: function () {
    
    this.getData();
  },
  mounted() {
    
  },
  methods: {
    getData() {
      const dateString = this.metricsEndDateMoment.format("YYYY-MM-DD");
      this.loading = true;
      
      Service.getMissingSiteStats(dateString)
        .then((response) => {
          this.missingKwhData = response.data.missing_site_stats; 
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting data:", error.response);
          this.loading = false;
        });
    },
  },
};
</script>
