<template>
  <v-data-table
    :headers="headers"
    :items="inverters"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :items-per-page=25
    hide-default-footer
    class="table"
    mobile-breakpoint="0">
    <!-- Inverter Name -->
    <template v-slot:item.name="{ item }">
      <div class="d-flex align-center ms-2">
        <span class="text-sm font-weight-normal text-body">
          {{ item.inverter_name }}
        </span>
      </div>
    </template>
    <!-- 30 day health -->
    <template v-slot:item.thirty_day_health="{ item }">
        <v-progress-linear :value="item.thirty_day_health"  style="width: 100px" height="15" :background-color="progressBackground(item.thirty_day_health)" :color="progressBarColor((item.thirty_day_health))" >
          <div class="text-center">{{ Math.ceil(item.thirty_day_health) }}%</div>
        </v-progress-linear>
    </template>
    
    <!-- day health -->
    <template v-slot:item.day_health="{ item }">
      <div class="wider-slot">
      <v-chip :color="getColor(item.day_health)" dark>
        {{ Math.round(item.day_health) }}
      </v-chip>
      </div>
    </template>
    <!-- q 30 day health -->
    <template v-slot:item.q_thirty_percentage="{ item }">
      <v-progress-linear :value="item.q_thirty_percentage"  style="width: 100px" height="15" :background-color="progressBackground(item.q_thirty_percentage)" :color="progressBarColor((item.q_thirty_percentage))" >
          <div class="text-center">{{ Math.ceil(item.q_thirty_percentage) }}%</div>
        </v-progress-linear>
    </template>
    <!-- q day health -->
    <template v-slot:item.q_percentage="{ item }">
      <v-chip :color="getColor(item.q_percentage)" dark>
        {{ Math.round(item.q_percentage) }}
      </v-chip>  
    </template>
    <template v-slot:item.monitoring_url="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <img
            :src="
              require('@/assets/img/' + item.monitoring_system + '.png')
            "
            v-if="item.master_id"
            v-on="on"
            v-on:click.stop.prevent="openWindow(item.monitoring_url)"
          />
        </template>
        <span>Monitoring Link</span>
      </v-tooltip>
      <template>
        <v-icon v-if="!item.monitoring_url && item.master_id" disabled>mdi-web-box</v-icon>
      </template>
    </template>
  </v-data-table>
</template>
<script>
import users from "./Users";
import Service from "@/services/Service.js";

export default {
  props: [
    'componentProps',
    'site_id',
    'site'
  ],
  name: "data-table-inverters",
  data () {
    return {
      sortBy: 'day_health',
      sortDesc: false,
      headers: [
        {
          text: "Inverter Name",
          align: "start",
          cellClass: "border-bottom",
          value: "inverter_name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
          width: 410
        },
        {
          text: "30 Day Health",
          value: "thirty_day_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Day Health",
          value: "day_health",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "InSite INV 30 day Health",
          value: "q_thirty_percentage",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "InSite INV Day Health",
          value: "q_percentage",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "",
          value: "monitoring_url"
        }
      ]
    }
  },
  methods: {
    openWindow: function (link) {
      window.open(link, "_blank");
    },
    progressBarColor(percentage) {
      let color = "";

      if (percentage < 75) {
        color = "red";
      } else if (percentage < 90) {
        color = "orange";
      } else {
        color = "green";
      }

      return color;
    },
    progressBackground(percentage) {
      if(percentage <= 0) {
        return "red";
      } else {
        return "grey lighten-2";
      }
    },
    getColor (percentage) {
      if (percentage < 75) {
        return 'red';
      } else if (percentage < 90) {
        return 'orange';
      } else {
        return 'green';
      }
    },
    // getSelectedComponents() {
    //   Service.getSelectedComponents()
    //     .then((response) => {
    //       this.selectedComponents = response.data;
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }
  },
  watch: {

  },

  computed: {
    // inverters: function() {
    //   // return this.problem_sites.length;
    //   if(this.componentProps && this.componentProps.inverter_metrics) {
    //     // TODO: 835 read selected components table and decide which inverters are shown
    //     const x = this.componentProps.inverter_metrics.filter((i) => {
    //       return i.site_id == this.site_id;
    //     })
    //     return x;
    //   } else {
    //     return [];
    //   }
    // },
    inverters: function() {
      if (this.componentProps && this.componentProps.inverter_metrics) {
        // Check if the site has a master_site_id
        if (this.site && this.site.master_site_id) {
          console.log(`master_site_id: ${this.site.master_site_id}`)
          // If there's a master_site_id, filter selectedComponents by master_site_id
          // grab our inverter id's
          const inv_ids = this.componentProps.selectedComponents.filter((s) => s.master_site_id == this.site.master_site_id).map((c) => c.component_id);
          return this.componentProps.inverter_metrics.filter(i => inv_ids.includes(i.inverter_id));
        } else {
          // If there's no master_site_id, simply filter by site_id
          return this.componentProps.inverter_metrics.filter((i) => {
            return i.site_id == this.site_id;
          });
        }
      } else {
        return [];
      }
    },
  },
  created: function () {
    // load selected components for user
    // this.getSelectedComponents();
  },
};
</script>
<style>
.wider-slot {
  /* width: 300px; 
  padding-right: 0px; 
  margin-left: 0px;  */
}
</style>