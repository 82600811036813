var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-6 card-shadow border-radius-xl py-4" },
    [
      _c(
        "v-row",
        { staticClass: "px-4", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "4" } },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "shadow-primary",
                  attrs: {
                    color: "bg-gradient-primary border-radius-xl mt-n8",
                    height: "64",
                    width: "64",
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "material-icons-round text-white",
                      attrs: { size: "24" },
                    },
                    [_vm._v("priority_high")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { staticClass: "text-end", attrs: { sm: "8" } }, [
            _c(
              "p",
              {
                staticClass:
                  "\n          text-sm\n          mb-0\n          text-capitalize text-body\n          font-weight-light\n        ",
              },
              [_vm._v(" High Alert Sites ")]
            ),
            _vm.loading
              ? _c(
                  "h4",
                  [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                  1
                )
              : _c(
                  "h4",
                  { staticClass: "text-h4 text-typo font-weight-bolder mb-0" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "text-h4 text-typo font-weight-bolder mb-0",
                        staticStyle: { "text-decoration": "underline" },
                        attrs: { text: "" },
                        on: { click: _vm.showProblemSites },
                      },
                      [_vm._v(" " + _vm._s(_vm.site_problem_count) + " ")]
                    ),
                  ],
                  1
                ),
          ]),
        ],
        1
      ),
      _c("hr", { staticClass: "dark horizontal mt-3 mb-4" }),
      _c(
        "v-row",
        { staticClass: "px-4" },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("p", { staticClass: "mb-0 text-body" }, [
              _c(
                "span",
                { staticClass: "text-success text-sm font-weight-bolder" },
                [_vm._v("+3%")]
              ),
              _c("span", { staticClass: "font-weight-light ms-1" }, [
                _vm._v("than last month"),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }